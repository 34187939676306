import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light:{
        background:"#F1F1F1",
        primary: '#113565',
        secondary: '#113565',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      dark:{
        background:"#434444",
          primary:"#2776E0",
          secondary:"#FFFFFF",
          accent: '#8c9eff'
      },
    },
  },
})
export default vuetify