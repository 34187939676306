<template>
  <v-container fluid style="width: 100%; height: 100%">
    <v-app-bar app color="" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Zebu"
          class="shrink"
          contain
          min-width="50"
          src="@/assets/imgs/z_white.svg"
          width="50"
        />
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex pr-1">
        <DarkModeSwitch
          @switched="onSwitched"
          :initialState="isDarkModeEnabled"
        />
      </div>
      <v-btn
        v-if="!loginstatus"
        @click="(drawer = true), drawer_function(), onmobile_checker()"
        dark
        color="primary"
      >
        Login
        <v-icon v-if="false">mdi-menu</v-icon>
      </v-btn>
      <v-menu bottom rounded offset-y v-if="loginstatus">
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="primary" size="40">
              <span class="white--text">{{ name[0] }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="brown" size=" 36">
                  <span class="white--text text-h5">{{ name[0] }}</span>
                </v-avatar>
                <h4 class="pt-3">
                  {{ name }} <br />
                  <v-chip class="ma-2" color="primary">
                    {{ clientcode }}
                  </v-chip>
                </h4>
                <p class="text-caption mt-1">
                  {{ mail }}
                </p>

                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  small
                  @click="(profiledisplay = true), (orderdisplay = false)"
                >
                  Edit Account
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text small @click="logout()">
                  Log Out
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-expand-transition>
      <v-row style="height: 100%">
        <!-- @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Left side Screen @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@-->
        <v-col
          :cols="rightcolno"
          style="border-right: solid 1px #e3e4e4"
          class="pa-0"
        >
          <div class="mt-6">
            <!-- pa-lg-6 -->
            <h2
              class="ml-lg-5 ml-2 secondary--text"
              style="padding-bottom: 10px"
            >
              Open IPO's
            </h2>

            <!-- @@@@@@ Ipo Open @@@@@@-->
            <div
              class="pl-lg-16"
              v-if="$vuetify.breakpoint.lg && checkforopen != false"
            >
              Currently no IPO is open kindly,check after some time
            </div>
            <v-row
              v-if="$vuetify.breakpoint.lg && checkforopen == false"
              class="ps-lg-6 pe-lg-12"
            >
              <v-col md="12" lg="1"> </v-col>
              <v-col md="12" lg="3">
                <h5
                  class="text--disabled"
                  style="padding-top: 10px; text-align: left"
                >
                  Company
                </h5>
              </v-col>
              <v-col lg="1" md="12">
                <h5
                  class="text--disabled"
                  style="padding-top: 10px; text-align: right"
                >
                  Per Lot
                </h5>
              </v-col>
              <v-col lg="2" md="12">
                <h5
                  class="text--disabled"
                  style="padding-top: 10px; text-align: right"
                >
                  Price Range
                </h5>
              </v-col>
              <!-- <v-col cols=1>
                      <h5 style="padding-top:10px;text-align:right;">Issue size</h5>
                    </v-col> -->
              <v-col lg="3" md="12">
                <h5
                  class="text--disabled"
                  style="padding-top: 10px; text-align: right"
                >
                  Bidding Date
                </h5>
              </v-col>
            </v-row>
            <v-expansion-panels flat focusable class="px-lg-6">
              <template v-for="(item, i) in desserts">
                <v-expansion-panel
                  :key="i"
                  v-if="
                    item.stats == 'Open' ||
                    item.stats == 'Close Today' ||
                    item.stats == 'Upcoming'
                  "
                  v-bind="(checkforopen = false)"
                >
                  <v-expansion-panel-header
                    class="pa-0 px-lg-4 py-lg-2"
                    :hide-actions="$vuetify.breakpoint.mdAndDown ? true : false"
                    @click="info_descrip(item.symbol), (skeleton_loader = true)"
                  >
                    <v-container fluid class="pa-0">
                      <v-row class="ma-0">
                        <v-col class="col-lg-1 col-2">
                          <v-list-item-avatar tile class="mt-0">
                            <v-img
                              style="border-radius: 10px"
                              :src="getAvatar(item.symbol)"
                            ></v-img>
                          </v-list-item-avatar>
                        </v-col>
                        <v-col cols="10" lg="3">
                          <h3 class="pt-1">{{ item.symbol }}</h3>
                          <h4 class="pt-1">{{ item.company_name }}</h4>
                        </v-col>

                        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="4">
                          <h5 class="text--disabled" style="text-align: left">
                            Per Lot
                          </h5>
                        </v-col>

                        <v-col cols="8" lg="1">
                          <h5 class="pt-1 text-right">
                            {{ item.minBidQuantity }}
                          </h5>
                        </v-col>

                        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="4">
                          <h5 class="text--disabled" style="text-align: left">
                            Price Range
                          </h5>
                        </v-col>

                        <v-col cols="8" lg="2">
                          <h5 class="pt-1 text-right">
                            ₹ {{ item.minPrice }} - ₹ {{ item.maxPrice }}
                          </h5>
                        </v-col>

                        <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="4">
                          <h5 class="text--disabled" style="text-align: left">
                            Bidding Date
                          </h5>
                        </v-col>

                        <v-col lg="3" cols="8" class="">
                          <h5 class="pt-1 text-right">
                            {{ item.biddingStartDate }} -
                            {{ item.biddingEndDate }}
                          </h5>
                          <h5 class="pt-1 text-right">
                            {{ item.dailyStartTime.slice(0, 3) }}00 AM -
                            {{ item.dailyEndTime.slice(0, 2) - 12 }}:00 PM
                          </h5>
                        </v-col>

                        <v-col lg="1" cols="12" class="ml-lg-4 pt-3 text-right">
                          <v-btn
                            v-if="!modifyscript.includes(item.symbol)"
                            :disabled="item.stats == 'Upcoming'"
                            block
                            color="primary"
                            @click.native.stop="
                              (drawer = true),
                                drawer_function(),
                                onmobile_checker(),
                                selected_ipo(
                                  item.symbol,
                                  item.minBidQuantity,
                                  item.minPrice,
                                  item.maxPrice,
                                  item.company_name,
                                  item.tickSize
                                ),
                                (basic[0].qty = item.minBidQuantity),
                                (basic[0].prc = parseInt(item.minPrice)),
                                ordertime(),
                                (orderdisplay = true),
                                (profiledisplay = false),
                                (modifydisplay = false)
                            "
                            ><span v-if="item.stats == 'Upcoming'"
                              >Upcoming</span
                            ><span v-if="item.stats == 'Open'"
                              >Apply</span
                            ></v-btn
                          >
                          <v-btn
                            block
                            color="green"
                            class="white--text"
                            v-if="modifyscript.includes(item.symbol)"
                            @click.native.stop="
                              modifyorder(item.symbol),
                                drawer_function(),
                                onmobile_checker(),
                                selected_ipo(
                                  item.symbol,
                                  item.minBidQuantity,
                                  item.minPrice,
                                  item.maxPrice,
                                  item.company_name,
                                  item.tickSize
                                ),
                                ordertime(),
                                (orderdisplay = true),
                                (profiledisplay = false)
                            "
                            >Modify</v-btn
                          >
                          <!-- <v-btn  block  color="primary" @click.native.stop="drawer=!drawer,drawer_function(),onmobile_checker()">Apply</v-btn> -->
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content eager class="pa-0">
                    <v-skeleton-loader
                      type="article"
                      style="padding-top: 10px"
                      v-if="skeleton_loader"
                    ></v-skeleton-loader>

                    <template v-for="(itemdesc, i) in desc_data">
                      <div
                        class="pa-5 mx-n6 mb-n4"
                        style="
                          border: 1.5px solid #d9d9d9;
                          border-top: 0;
                          border-radius: 0px 0px 15px 15px;
                        "
                        :key="i"
                      >
                        <h3 class="d-inline-block pt-3 mb-2">Company Snap</h3>
                        <v-btn color="primary" class="" text small
                          ><a
                            style="text-decoration: none"
                            :href="itemdesc.rhp_doc_link"
                            target="_blank"
                          >
                            RHP Docs</a
                          ></v-btn
                        >
                        <p class="body-1 text-justify">
                          {{ itemdesc.Description }}
                        </p>
                        <!-- {{itemdesc}} -->
                        <v-row class="pb-5">
                          <v-col lg="5">
                            <v-card-title
                              style="font-size: 15px"
                              class="pb-0 pl-0 pt-0"
                            >
                              Subscription Status</v-card-title
                            >
                            <!-- <v-card-text style="font-size:11px" class=" py-0 mt-n2 pl-0">last updated {{currentdate}} 5:00 PM </v-card-text> -->

                            <v-card-text class="pa-0">
                              <v-simple-table
                                dense
                                class="pt-2 pl-0 pe-lg-16 me-lg-16"
                              >
                                <thead></thead>
                                <tbody>
                                  <tr>
                                    <td
                                      class="px-0"
                                      style="border-bottom: none"
                                    >
                                      Qualified Institutional Buyer
                                    </td>
                                    <td
                                      class="px-0"
                                      style="border-bottom: none"
                                    >
                                      {{ itemdesc.subscription_status_qib }} x
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      class="px-0"
                                      style="border-bottom: none"
                                    >
                                      Non-Institutional Investor
                                    </td>
                                    <td
                                      class="px-0"
                                      style="border-bottom: none"
                                    >
                                      {{ itemdesc.subscription_status_nil }} x
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      class="px-0"
                                      style="border-bottom: none"
                                    >
                                      Retail Investor
                                    </td>
                                    <td
                                      class="px-0"
                                      style="border-bottom: none"
                                    >
                                      {{
                                        itemdesc.subscription_status_retail
                                      }}
                                      x
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-card-text>
                          </v-col>

                          <v-col lg="5" class="pt-1 ml-md-16">
                            <v-card-title
                              style="font-size: 15px"
                              class="pb-0 pt-2 pl-0"
                              >Financial status</v-card-title
                            >

                            <v-simple-table dense class="border">
                              <thead>
                                <tr>
                                  <th class="pl-0 text-left">Financial Year</th>
                                  <th class="text-right">Revenue (₹ Cr.)</th>
                                  <th class="text-right">P&L (₹ Cr.)</th>
                                  <th class="text-right">Assets (₹ Cr.)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    class="
                                      pl-0
                                      text-left
                                      caption
                                      font-weight-bold
                                    "
                                  >
                                    March
                                    {{
                                      itemdesc.financial_info_dates
                                        .split(",")[0]
                                        .split("-")[0]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_total_revenue.split(
                                        ","
                                      )[0]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_profit_after_tax.split(
                                        ","
                                      )[0]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_total_assets.split(
                                        ","
                                      )[0]
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="
                                      pl-0
                                      text-left
                                      caption
                                      font-weight-bold
                                    "
                                  >
                                    March
                                    {{
                                      itemdesc.financial_info_dates
                                        .split(",")[1]
                                        .split("-")[0]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_total_revenue.split(
                                        ","
                                      )[1]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_profit_after_tax.split(
                                        ","
                                      )[1]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_total_assets.split(
                                        ","
                                      )[1]
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="
                                      pl-0
                                      text-left
                                      caption
                                      font-weight-bold
                                    "
                                  >
                                    March
                                    {{
                                      itemdesc.financial_info_dates
                                        .split(",")[2]
                                        .split("-")[0]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_total_revenue.split(
                                        ","
                                      )[2]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_profit_after_tax.split(
                                        ","
                                      )[2]
                                    }}
                                  </td>
                                  <td
                                    class="text-right caption font-weight-bold"
                                  >
                                    {{
                                      itemdesc.financial_info_total_assets.split(
                                        ","
                                      )[2]
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-col>
                        </v-row>

                        <div class="mb-lg-n1">Time Line</div>
                        <v-stepper
                          v-if="itemdesc.tentative_ipo_open_date != ''"
                          vertical
                          flat
                          class="pb-0"
                          :value="stepsize"
                          v-bind="
                            checkipostatus(
                              itemdesc.tentative_ipo_open_date,
                              itemdesc.tentative_ipo_close_date,
                              itemdesc.tentative_allotment_date,
                              itemdesc.tentative_initial_refund,
                              itemdesc.tentative_credits_share_dm,
                              itemdesc.tentative_ipo_listing_date
                            )
                          "
                        >
                          <v-stepper-header
                            height="100%"
                            style="overflow: visible"
                            class="flex-column flex-md-row pa-0"
                          >
                            <v-stepper-step
                              class="pa-1"
                              step="1"
                              :complete="stepsize > 1"
                            >
                              <v-list-item
                                class="
                                  float-left float-lg-none
                                  align-lg-end
                                  pa-0
                                  grey--text
                                  font-weight-bold
                                  caption
                                "
                                >Offer start</v-list-item
                              >
                              <v-list-item
                                class="
                                  float-right float-lg-none
                                  align-lg-start
                                  pa-0
                                  subtitle-2
                                "
                                >{{
                                  itemdesc.tentative_ipo_open_date
                                }}</v-list-item
                              >
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                              class="pa-1"
                              step="2"
                              :complete="stepsize > 2"
                            >
                              <v-list-item
                                class="
                                  float-left float-lg-none
                                  align-lg-end
                                  pa-0
                                  grey--text
                                  font-weight-bold
                                  caption
                                "
                                >Offer end</v-list-item
                              >
                              <v-list-item
                                class="
                                  float-right float-lg-none
                                  align-lg-start
                                  pa-0
                                  subtitle-2
                                "
                                >{{
                                  itemdesc.tentative_ipo_close_date
                                }}</v-list-item
                              >
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                              step="3"
                              class="pa-1"
                              :complete="stepsize > 3"
                            >
                              <v-list-item
                                class="
                                  float-left
                                  align-lg-end
                                  float-lg-none
                                  pa-0
                                  grey--text
                                  font-weight-bold
                                  caption
                                "
                                >Allotment</v-list-item
                              >
                              <v-list-item
                                class="
                                  float-right float-lg-none
                                  align-lg-start
                                  pa-0
                                  subtitle-2
                                "
                                >{{
                                  itemdesc.tentative_allotment_date
                                }}</v-list-item
                              >
                            </v-stepper-step>
                            <v-divider></v-divider>

                            <v-stepper-step
                              step="4"
                              class="pa-1"
                              :complete="stepsize > 4"
                            >
                              <v-list-item
                                class="
                                  float-left float-lg-none
                                  align-lg-end
                                  pa-0
                                  grey--text
                                  font-weight-bold
                                  caption
                                "
                                >Refund initiation</v-list-item
                              >
                              <v-list-item
                                class="
                                  float-right float-lg-none
                                  align-lg-start
                                  pa-0
                                  subtitle-2
                                "
                                >{{
                                  itemdesc.tentative_initial_refund
                                }}</v-list-item
                              >
                            </v-stepper-step>
                            <v-divider></v-divider>

                            <v-stepper-step
                              step="5"
                              class="pa-1"
                              :complete="stepsize > 5"
                            >
                              <v-list-item
                                class="
                                  float-left float-lg-none
                                  align-lg-end
                                  pa-0
                                  grey--text
                                  font-weight-bold
                                  caption
                                "
                                >Demat transfer</v-list-item
                              >
                              <v-list-item
                                class="
                                  float-right float-lg-none
                                  align-lg-start
                                  pa-0
                                  subtitle-2
                                "
                                >{{
                                  itemdesc.tentative_credits_share_dm
                                }}</v-list-item
                              >
                            </v-stepper-step>
                            <v-divider></v-divider>

                            <v-stepper-step
                              step="6"
                              class="pa-1"
                              :complete="stepsize > 6"
                            >
                              <v-list-item
                                class="
                                  float-left float-lg-none
                                  align-lg-end
                                  pa-0
                                  grey--text
                                  font-weight-bold
                                  caption
                                "
                                >Listing</v-list-item
                              >
                              <v-list-item
                                class="
                                  float-right float-lg-none
                                  align-lg-start
                                  pa-0
                                  subtitle-2
                                "
                                >{{
                                  itemdesc.tentative_ipo_listing_date
                                }}</v-list-item
                              >
                            </v-stepper-step>
                          </v-stepper-header>
                        </v-stepper>
                      </div>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>

            <!-- @@@@@@ Ipo Close @@@@@@-->
            <!-- <h2 class="secondary--text ml-lg-5 ml-2" style="padding-top:30px;">Closed IPO's</h2>
            <v-row v-if="$vuetify.breakpoint.lg" class="ps-6 pe-12" >
                    <v-col  lg=1>
                    </v-col>
                    <v-col class="text-muted pb-0" cols=2>
                      <h5  class="text--disabled" style="padding-top:10px;text-align:left;">Company</h5>
                    </v-col>
                    <v-col  lg=1>
                      <h5 class="text--disabled" style="padding-top:10px;text-align:right;">Per Lot</h5>
                    </v-col>
                    <v-col  lg=2>
                      <h5 class="text--disabled" style="padding-top:10px;text-align:right;">Price Range</h5>
                    </v-col>
                    <v-col cols=1>
                      <h5 style="padding-top:10px;text-align:right;">Issue size</h5>
                    </v-col>
                    <v-col  lg=3>
                      <h5 class="text--disabled" style="padding-top:10px;text-align:center;">Bidding Date</h5>
                    </v-col>
                    <v-col  lg=1>
                      <h5 class="text--disabled" style="padding-top:10px;text-align:right;">Issue Price </h5>
                    </v-col>
                    <v-col  lg=1>
                      <h5 class="text--disabled" style="padding-top:10px;text-align:right;">Listed Price</h5>
                    
                    </v-col>
                 
                  </v-row>
      <v-expansion-panels flat focusable>

        <template v-for="(item,i) in desserts">
        <v-expansion-panel
          :key="i"
          v-if="item.stats != 'Open' && item.stats != 'Close Today' && item.stats != 'Upcoming' " 
        >
          <v-expansion-panel-header  class="pa-0 px-lg-4 py-lg-2" :hide-actions="$vuetify.breakpoint.mdAndDown ? true:false" @click="info_descrip(item.symbol)">
     
                 <v-row class="ma-0">
                    <v-col class="pb-0" cols=2 lg=1>
                  
                      <v-list-item-avatar
                        tile>
                         <v-img style="border-radius:10px" :src="getAvatar(item.symbol)"></v-img>
                      </v-list-item-avatar>
                    </v-col>
                    <v-col class="pb-0" cols=10 lg=2>
                      <h3 style="padding-top:10px;">{{ item.symbol }}</h3>
                       <h4 style="padding-top:10px;">{{ item.company_name }}</h4>
                    </v-col>

                     <v-col class="pb-0" v-if="$vuetify.breakpoint.mdAndDown" cols=4>
                           <h5 class="text--disabled" style="text-align:left;">Per Lot</h5>
                    </v-col>
                    <v-col class="pb-0" cols=8 lg=1>
                      <h5 class="pt-1 text-right">{{item.minBidQuantity }}</h5>
                    </v-col>

                     <v-col class="pb-0"  v-if="$vuetify.breakpoint.mdAndDown" cols=4>
                            <h5 class="text--disabled" style="text-align:left;">Price Range</h5>
                    </v-col>
                    <v-col class="pb-0" cols=8 lg=2>
                       <h5 class="pt-1 text-right">₹ {{ item.minPrice }} - ₹ {{ item.maxPrice }}</h5>
                    </v-col>

                       <v-col class="pb-1"  v-if="$vuetify.breakpoint.mdAndDown" cols=4>
                           <h5 class="text--disabled" style="text-align:left;">Bidding Date</h5>
                    </v-col>
                    <v-col class="pb-1" cols=8 lg=3>
                      <h5 class="pt-1 text-right"> {{ item.biddingStartDate }} - {{ item.biddingEndDate }}</h5>
                    </v-col>

                     <v-col class="pb-0" cols=5 lg=1>
                       <h5 v-if="$vuetify.breakpoint.mdAndDown" class="text--disabled" style="padding-bottom:10px;text-align:right;">Issue Price </h5>
                      <h5 class="pt-1 text-right">₹ {{ item.minPrice }} </h5>
                    </v-col>
                    <v-col class="pb-0" cols=5 lg=1>
                        <h5 v-if="$vuetify.breakpoint.mdAndDown" class="text--disabled" style="padding-bottom:10px;text-align:right;">Listed Price</h5>
                      <h5 class="pt-1 text-right">₹ {{ item.maxPrice }}</h5>
                      <h5 class="pt-1 text-right" style="color:green"><v-icon style="font-size:16px;color:green">mdi-arrow-up</v-icon> {{Math.floor((100/item.minPrice)*item.maxPrice-100)}} %</h5>
                    </v-col>
                  </v-row>
          
          </v-expansion-panel-header>
          <v-expansion-panel-content  eager class="pa-0">
                <template v-for="(item,i) in desc_data">
                         <div class="pa-5 mx-n6" style="border:1.5px solid #d9d9d9;border-radius:0px 0px 15px 15px" :key="i">

                        <h3 class="d-inline-block pt-3 mb-2">Company Snap</h3>
                         <v-btn color=primary class="" text small>RHP Docs</v-btn>
                          <p class="body-1 text-justify">
                              {{item.Description}}
                          </p>

              <v-row class="pb-5">
               <v-col lg=5>
                           <v-card-title style="font-size:15px" class="pb-0 pl-0 pt-0"> Subscription Status</v-card-title>
                            <v-card-text style="font-size:11px" class=" py-0 mt-n2 pl-0">last updated 21-12-2021 1:00 PM </v-card-text>

               <v-card-text class="pa-0">
                  <v-simple-table dense class="pt-2 pl-0">
                <thead>
                </thead>
                <tbody >
                  <tr>
                    <td class="pl-0" style=" border-bottom: none;">Qualified Institutional Buyer</td>
                    <td class="pl-0" style=" border-bottom: none;">142.65x</td>
                  </tr>
                  <tr>
                    <td class="pl-0" style=" border-bottom: none;">Non-Institutional Investor</td>
                    <td class="pl-0" style=" border-bottom: none;">4.65x</td>
                  </tr>
                  <tr>
                    <td class="pl-0" style=" border-bottom: none;">Retail Investor</td>
                    <td class="pl-0" style=" border-bottom: none;">412.65x</td>
                  </tr>
                </tbody>
              </v-simple-table>
              </v-card-text>
                </v-col>
                     
                    <v-col lg=5 class="pt-1 ml-md-16">
                       <v-card-title style="font-size:15px" class="pb-0  pt-2 pl-0">Financial status</v-card-title>
                         
                            <v-simple-table dense class="border">
                                  <thead>
                                    <tr>
                                      <th class="pl-0 text-left ">Financial Year</th>
                                      <th class="text-right ">Revenue (₹ Cr.)</th>
                                      <th class="text-right ">P&L (₹ Cr.)</th>
                                      <th class="text-right ">Assets (₹ Cr.)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td class="pl-0 text-left caption font-weight-bold">March 2019</td>
                                      <td class="text-right caption font-weight-bold">6518.45</td>
                                      <td class="text-right caption font-weight-bold">-2364.53</td>
                                      <td class="text-right caption font-weight-bold">-3943.84</td>
                                    </tr>
                                    <tr>
                                      <td class="pl-0 text-left caption font-weight-bold">March 2020</td>
                                      <td class="text-right caption font-weight-bold">13,413.26</td>
                                      <td class="text-right caption font-weight-bold">-13,122.77</td>
                                      <td class="text-right caption font-weight-bold">3943.84</td>
                                    </tr>
                                    <tr>
                                      <td class="pl-0 text-left caption font-weight-bold">March 2021</td>
                                      <td class="text-right caption font-weight-bold">4157.38</td>
                                      <td class="text-right caption font-weight-bold">-3943.84</td>
                                      <td class="text-right caption font-weight-bold">3943.84</td>
                                      
                                    </tr>
                                  </tbody>
                            </v-simple-table>
                          </v-col>

                        </v-row>
                                 

                                <div class="mb-lg-n1">Time Line</div>
                             <v-stepper  vertical flat class="pb-0"
                                :value="stepsize"
                                v-bind=  checkipostatus(item.tentative_ipo_open_date,item.tentative_ipo_close_date,item.tentative_allotment_date,item.tentative_initial_refund,item.tentative_credits_share_dm,item.tentative_ipo_listing_date)>
                                <v-stepper-header height="100%" style="overflow:visible" class="flex-column flex-md-row pa-0">
                                  <v-stepper-step class="pa-1"
                                    step="1"
                                    :complete="stepsize>1">
                                    <v-list-item class="float-left float-lg-none align-lg-end pa-0 grey--text font-weight-bold caption">Offer start</v-list-item>
                                     <v-list-item class="float-right float-lg-none align-lg-start pa-0 subtitle-2">{{item.tentative_ipo_open_date}}</v-list-item>
                                  </v-stepper-step>

                                  <v-divider></v-divider>

                                  <v-stepper-step class="pa-1"
                                    step="2"
                                     :complete="stepsize>2">
                                     <v-list-item class="float-left float-lg-none align-lg-end pa-0 grey--text font-weight-bold caption">Offer end</v-list-item>
                                      <v-list-item class="float-right float-lg-none align-lg-start pa-0 subtitle-2">{{item.tentative_ipo_close_date}}</v-list-item>
                                  </v-stepper-step>

                                  <v-divider></v-divider>

                                  <v-stepper-step step="3" class="pa-1"
                                   :complete="stepsize>3">
                                  <v-list-item class="float-left align-lg-end float-lg-none pa-0 grey--text font-weight-bold caption">Allotment</v-list-item>
                                  <v-list-item class="float-right float-lg-none align-lg-start pa-0 subtitle-2">{{item.tentative_allotment_date}}</v-list-item>
                                  </v-stepper-step>
                                      <v-divider></v-divider>

                                  <v-stepper-step step="4" class="pa-1"
                                   :complete="stepsize>4">
                                    <v-list-item class="float-left float-lg-none align-lg-end  pa-0 grey--text font-weight-bold caption">Refund initiation</v-list-item>
                                    <v-list-item class="float-right float-lg-none align-lg-start pa-0 subtitle-2">{{item.tentative_initial_refund}}</v-list-item>
                                  </v-stepper-step>
                                      <v-divider></v-divider>

                                  <v-stepper-step step="5" class="pa-1"
                                   :complete="stepsize>5">
                                   <v-list-item class="float-left float-lg-none align-lg-end pa-0 grey--text font-weight-bold caption">Demat transfer</v-list-item>
                                   <v-list-item class="float-right float-lg-none align-lg-start pa-0 subtitle-2">{{item.tentative_credits_share_dm}}</v-list-item>
                                  </v-stepper-step>
                                      <v-divider></v-divider>

                                  <v-stepper-step step="6" class="pa-1"
                                   :complete="stepsize>6">
                                  
                                      <v-list-item class="float-left float-lg-none align-lg-end  pa-0  grey--text font-weight-bold caption">Listing</v-list-item>
                                      <v-list-item class="float-right float-lg-none align-lg-start pa-0 subtitle-2">{{item.tentative_ipo_listing_date}}</v-list-item>

                                  </v-stepper-step>
                                </v-stepper-header>
                              </v-stepper>
                        
                   
                    </div>
              </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
         </template>
      </v-expansion-panels>-->
          </div>
        </v-col>

        <!-- @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Right side Screen @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@-->
        <v-col
          class="pa-0"
          v-if="drawer && $vuetify.breakpoint.lg"
          :cols="leftcolno"
        >
          <div v-if="!loginstatus" class="rightCardFloat">
            <v-card
              elevation="0"
              v-if="clientcode_screen"
              height="500px"
              style="margin-top: 0; padding-top: 20px; overflow: auto"
            >
              <v-card-title>
                <v-btn
                  @click="(drawer = false), drawer_function()"
                  class="primary--text"
                  rounded
                  small
                  text
                  dense
                  ><v-icon>mdi-arrow-left</v-icon></v-btn
                >
              </v-card-title>
              <div style="padding: 40px 10px">
                <div>
                  <v-img
                    style="margin: 0px auto"
                    alt="Zebu"
                    class="shrink"
                    contain
                    min-width="50"
                    src="@/assets/imgs/z.svg"
                    width="50"
                  />
                </div>

                <v-card-title
                  class="secondary--text justify-center text-center"
                >
                  Login</v-card-title
                >
                <!-- <div class="justify-center text-center " style="font-size:14px;color:#757575;">Enter your Client Code</div> -->
                <v-card-text style="text-align: center">
                  <v-btn
                    color="primary"
                    block
                    @click="clientcode_verify()"
                    :disabled="loader"
                    >Sign in with Zebull
                  </v-btn>
                  <div align="center">
                    <v-progress-linear
                      v-if="loader && clientcode_screen"
                      indeterminate
                      color="primary"
                      style="width: 99%"
                    ></v-progress-linear>
                  </div>
                  <div style="padding-top: 10px">
                    Don't have an account yet?
                    <a
                      style="text-decoration: none"
                      target="_blank"
                      href="https://zkyc.zebull.in/zebuetrade/signup"
                      >Sign Up</a
                    >
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </div>
          <div v-if="orderdisplay && loginstatus" class="rightCardFloat pb-4">
            <v-card id="box" elevation="0">
              <v-card-text class="pa-0 py-2" style="">
               
                <div style="width: 300px; margin: 0px auto; padding-top: 30px">
                  <div class="text-center justify-center align-center">
                    <v-row>
                      <v-col lg="2">
                        <v-list-item-avatar tile>
                          <v-img
                            style="border-radius: 10px"
                            :src="getAvatar(selectedsymbol)"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-col>
                      <v-col style="text-align: left">
                        <h3 style="padding-top: 6px">{{ selectedsymbol }}</h3>
                        <h4 style="margin-right: -12px">
                          {{ selectedcompany.substring(0, 25) }}...
                        </h4>
                      </v-col>
                      <v-btn
                        x-small
                        text
                        @click="(orderdisplay = false), (profiledisplay = true)"
                        ><v-icon small>mdi-close</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                  <v-alert
                    text
                    dense
                    elevation="2"
                    v-if="ordertimedisp"
                    icon="mdi-clock-fast"
                    color="primary"
                    style="margin-top: 12px"
                  >
                    IPO open from 10 AM to {{ipoendtiming-12}} PM
                  </v-alert>
                  <!-- <div style="text-align:right; margin:10px -6px 0 0"><v-btn color="primary" rounded  @click="addRow(selectedqty)" v-if="basic.length < 3">Add<v-icon dark>mdi-plus</v-icon> </v-btn></div> -->
                  <div
                    v-for="(bitem, index) in basic"
                    :key="bitem.id"
                    style="padding-top: 20px"
                  >
                    <!-- <div style="float:right">
                                      <v-btn v-if="basic.length != 1" x-small  text  v-on:click="basic.splice(index, 1)" style="margin-top:1px;">
                                              <v-icon
                                                dark
                                                small
                                                color="grey"
                                                ele
                                              >
                                                mdi-close
                                              </v-icon>
                                      </v-btn>
                                    </div> -->
                    <v-row
                      align="center"
                      style="padding-left: 10px; padding-right: 10px"
                    >
                      <v-divider></v-divider>
                      <v-chip
                        x-small
                        style="color: white; background-color: black"
                        >BID {{ index + 1 }}</v-chip
                      >
                      <v-divider></v-divider>
                      <v-btn
                        v-if="basic.length != 1"
                        x-small
                        text
                        v-on:click="basic.splice(index, 1)"
                        style="margin-top: 1px"
                      >
                        <v-icon dark small color="grey" ele> mdi-close </v-icon>
                      </v-btn>
                    </v-row>
                    <div style="padding-top: 10px">
                      <v-text-field
                        type="number"
                        v-model="bitem.qty"
                        @keyup="qty1error(index)"
                        :step="selectedqty"
                        :min="selectedqty"
                      >
                        <template v-slot:label>
                          <strong>Qty</strong> (Lot : {{ selectedqty }} *
                          {{ parseInt(bitem.qty / selectedqty) }})
                        </template>
                      </v-text-field>
                      <p
                        style="margin-top: -20px; font-size: 12px; color: red"
                        v-if="qty1errormsg && index == 0"
                      >
                        {{ qty1errormsg }}
                      </p>
                      <p
                        style="margin-top: -20px; font-size: 12px; color: red"
                        v-if="qty2errormsg && index == 1"
                      >
                        {{ qty2errormsg }}
                      </p>
                      <p
                        style="margin-top: -20px; font-size: 12px; color: red"
                        v-if="qty3errormsg && index == 2"
                      >
                        {{ qty3errormsg }}
                      </p>
                      <v-text-field
                        type="number"
                        @keyup="prc1error(index)"
                        v-model="bitem.prc"
                        prefix="₹"
                        :disabled="bitem.cutoff"
                        :min="selectedminprc"
                        :max="selectedmaxprc"
                        :step="selectedtickprc"
                      >
                        <template v-slot:label>
                          <strong>Price</strong> (₹{{
                            parseInt(selectedminprc)
                          }}
                          - ₹{{ parseInt(selectedmaxprc) }})
                        </template>
                      </v-text-field>
                      <p
                        style="margin-top: -20px; font-size: 12px; color: red"
                        v-if="prc1errormsg && index == 0"
                      >
                        {{ prc1errormsg }}
                      </p>
                      <p
                        style="margin-top: -20px; font-size: 12px; color: red"
                        v-if="prc2errormsg && index == 1"
                      >
                        {{ prc2errormsg }}
                      </p>
                      <p
                        style="margin-top: -20px; font-size: 12px; color: red"
                        v-if="prc3errormsg && index == 2"
                      >
                        {{ prc3errormsg }}
                      </p>
                    </div>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="bitem.cutoff"
                          x-small
                          label="Cut-off"
                          style="
                            padding: 0;
                            margin-top: -10px;
                            transform: scale(0.9);
                            height: -10px;
                          "
                          @click="
                            (bitem.prc = parseInt(selectedmaxprc)),
                              prc1error(index)
                          "
                        ></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="primary"
                          text
                          x-small
                          @click="addRow(selectedqty, selectedminprc)"
                          :disabled="
                            basic.length > index + 1 || basic.length > 2
                          "
                          style="
                            padding: 0;
                            margin-top: -6px;
                            float: right;
                            padding: 10px;
                          "
                          ><v-icon dark small>mdi-plus</v-icon> BID
                          {{ index + 2 }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider></v-divider>

                  <v-text-field
                    style="padding-top: 20px"
                    v-model="vpa"
                    hide-details
                    label="UPI Id"
                    dense
                  ></v-text-field>
                  <v-checkbox v-model="ordercheckbox">
                    <template v-slot:label>
                      <p
                        style="
                          font-size: 12px;
                          margin-bottom: -50px;
                          text-align: justify;
                        "
                      >
                        I hereby undertake that, I have read the Red Herring
                        Prospectus and iam an eligible UPI bidder as per the
                        applicable provisions of the SEBI (Issue of capital and
                        Disclosure Requirement) Regulation, 2009.
                      </p>
                    </template>
                  </v-checkbox>
                  <p
                    style="
                      margin-top: 50px;
                      font-size: 12px;
                      color: red;
                      text-align: center;
                    "
                  >
                    {{ priceexceederror }}
                  </p>
                  <v-btn
                    block
                    :disabled="
                      !ordercheckbox ||
                      qty1errormsg != null ||
                      qty2errormsg != null ||
                      qty3errormsg != null ||
                      prc1errormsg != null ||
                      prc2errormsg != null ||
                      prc3errormsg != null ||
                      loader || ordertimedisp
                    "
                    color="primary"
                    @click="order(), (loader = true)"
                    v-if="!modifydisplay"
                  >   <!---->
                    Invest<v-icon small>mdi-currency-inr</v-icon>
                    <span v-if="basic.length == 1"
                      >{{
                        isNaN(
                          Math.max.apply(null, [
                            parseInt(basic[0]["qty"]) *
                              parseInt(basic[0]["prc"]),
                          ])
                        )
                          ? 0
                          : Math.max.apply(null, [
                              parseInt(basic[0]["qty"]) *
                                (parseInt(basic[0]["prc"])-parseInt(45)),
                            ])
                      }}
                    </span>
                    <span v-if="basic.length == 2"
                      >{{
                        isNaN(
                          Math.max.apply(null, [
                            parseInt(basic[0]["qty"]) *
                              parseInt(basic[0]["prc"]),
                            parseInt(basic[1]["qty"]) *
                              parseInt(basic[1]["prc"]),
                            0,
                          ])
                        )
                          ? 0
                          : Math.max.apply(null, [
                              parseInt(basic[0]["qty"]) *
                                (parseInt(basic[0]["prc"])-parseInt(45)),
                              parseInt(basic[1]["qty"]) *
                                (parseInt(basic[1]["prc"])-parseInt(45)),
                              0,
                            ])
                      }}
                    </span>
                    <span v-if="basic.length == 3"
                      >{{
                        isNaN(
                          Math.max.apply(null, [
                            parseInt(basic[0]["qty"]) *
                              parseInt(basic[0]["prc"]),
                            parseInt(basic[1]["qty"]) *
                              parseInt(basic[1]["prc"]),
                            parseInt(basic[2]["qty"]) *
                              parseInt(basic[2]["prc"]),
                            0,
                          ])
                        )
                          ? 0
                          : Math.max.apply(null, [
                              parseInt(basic[0]["qty"]) *
                                (parseInt(basic[0]["prc"])-parseInt(45)),
                              parseInt(basic[1]["qty"]) *
                                (parseInt(basic[1]["prc"])-parseInt(45)),
                              parseInt(basic[2]["qty"]) *
                                (parseInt(basic[2]["prc"])-parseInt(45)),
                              0,
                            ])
                      }}
                    </span>
                  </v-btn>
                  <v-progress-linear
                    v-if="loader && !modifydisplay"
                    indeterminate
                    color="primary"
                    style="width: 99%"
                  ></v-progress-linear>
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        color="red"
                        block
                        :disabled="!ordercheckbox || loader"
                        class="white--text"
                        @click="cancelorderplace()"
                        v-if="modifydisplay"
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="8">
                      <v-btn
                        block
                        :disabled="
                          !ordercheckbox ||
                          qty1errormsg != null ||
                          qty2errormsg != null ||
                          qty3errormsg != null ||
                          prc1errormsg != null ||
                          prc2errormsg != null ||
                          prc3errormsg != null ||
                          loader
                        "
                        color="primary"
                        @click="modifyorderplace()"
                        v-if="modifydisplay"
                      >
                        Modify<v-icon small>mdi-currency-inr</v-icon>
                        <span v-if="basic.length == 1"
                          >{{
                            isNaN(
                              Math.max.apply(null, [
                                parseInt(basic[0]["qty"]) *
                                  parseInt(basic[0]["prc"]),
                              ])
                            )
                              ? 0
                              : Math.max.apply(null, [
                                  parseInt(basic[0]["qty"]) *
                                    parseInt(basic[0]["prc"]),
                                ])
                          }}
                        </span>
                        <span v-if="basic.length == 2"
                          >{{
                            isNaN(
                              Math.max.apply(null, [
                                parseInt(basic[0]["qty"]) *
                                  parseInt(basic[0]["prc"]),
                                parseInt(basic[1]["qty"]) *
                                  parseInt(basic[1]["prc"]),
                                0,
                              ])
                            )
                              ? 0
                              : Math.max.apply(null, [
                                  parseInt(basic[0]["qty"]) *
                                    parseInt(basic[0]["prc"]),
                                  parseInt(basic[1]["qty"]) *
                                    parseInt(basic[1]["prc"]),
                                  0,
                                ])
                          }}
                        </span>
                        <span v-if="basic.length == 3"
                          >{{
                            isNaN(
                              Math.max.apply(null, [
                                parseInt(basic[0]["qty"]) *
                                  parseInt(basic[0]["prc"]),
                                parseInt(basic[1]["qty"]) *
                                  parseInt(basic[1]["prc"]),
                                parseInt(basic[2]["qty"]) *
                                  parseInt(basic[2]["prc"]),
                                0,
                              ])
                            )
                              ? 0
                              : Math.max.apply(null, [
                                  parseInt(basic[0]["qty"]) *
                                    parseInt(basic[0]["prc"]),
                                  parseInt(basic[1]["qty"]) *
                                    parseInt(basic[1]["prc"]),
                                  parseInt(basic[2]["qty"]) *
                                    parseInt(basic[2]["prc"]),
                                  0,
                                ])
                          }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-progress-linear
                    v-if="loader && modifydisplay"
                    indeterminate
                    color="primary"
                    style="width: 99%"
                  ></v-progress-linear>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="pa-5" v-if="profiledisplay && loginstatus">
            <v-banner shaped>
              <v-avatar slot="icon" color="primary" size="40">
                <span class="white--text">{{ name[0] }}</span>
              </v-avatar>
              <h4 v-if="currenttime < 12">
                Good Morning<br />
                {{ name }}
              </h4>
              <h4 v-if="currenttime >= 12 && currenttime < 18">
                Good Afternoon<br />
                {{ name }}
              </h4>
              <h4 v-if="currenttime >= 18">
                Good Evening <br />
                {{ name }}
              </h4>
            </v-banner>
            <v-tabs v-model="tab" fixed-tabs>
              <v-tab href="#tab-1"> Orders </v-tab>

              <v-tab disabled>
                <!-- History href="#tab-2" -->
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
                <v-card flat>
                  <v-card-text style="padding: 0">
                    <v-list>
                      <v-list-item style="padding: 0">
                        <v-list-item-content>
                          <v-row style="padding: 0">
                            <v-col>
                              <h5 class="text--disabled">Symbol</h5>
                              <h5 class="text--disabled">Application Number</h5>
                            </v-col>
                            <v-col style="text-align: right">
                              <h5 class="text--disabled">Qty</h5>
                              <h5 class="text--disabled">Price</h5>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      <template v-for="item in orderhistory">
                        <v-hover
                          v-slot:default="{ hover }"
                          :key="item.applicationNumber"
                        >
                          <v-list-item
                            :key="item[0].applicationNumber"
                            style="padding: 0"
                          >
                            <v-overlay
                              absolute
                              :opacity="0.9"
                              :value="hover"
                              color="white"
                            >
                              <v-row v-if="item[0].status == 'success'">
                                <v-col>
                                  <v-btn color="primary" small> Modify </v-btn>
                                </v-col>
                                <v-col>
                                  <v-btn color="red" small> Cancel </v-btn>
                                </v-col>
                                <v-col>
                                  <v-btn
                                    color="sucess"
                                    small
                                    @click="
                                      (overlay = !overlay), (orderinfo = item)
                                    "
                                  >
                                    Info
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <div
                                v-if="
                                  item[0].status == 'failed' &&
                                  !item[0].activityType1 == ''
                                "
                                style="color: red"
                              >
                                Reason : {{ item[0].reason }}
                              </div>
                            </v-overlay>
                            <v-list-item-content>
                              <v-row>
                                <!-- <v-col cols=1>
                                <v-icon v-if="item.status == 'success'" color=success>mdi-check-circle-outline</v-icon>
                                <v-icon v-if="item.status == 'failed'" color=red>mdi-close-circle-outline</v-icon>
                              </v-col> -->
                                <v-col>
                                  <v-list-item-title
                                    >{{ item[0].symbol }}
                                    <v-chip
                                      v-if="
                                        item[0].activityType1 == 'new' &&
                                        item[0].status == 'success'
                                      "
                                      label
                                      x-small
                                      color="success"
                                      >Open</v-chip
                                    >
                                    <v-chip
                                      v-if="
                                        item[0].activityType1 == 'new' &&
                                        item[0].status == 'failed'
                                      "
                                      label
                                      x-small
                                      color="error"
                                      >Rejected</v-chip
                                    >
                                    <v-chip
                                      v-if="
                                        item[0].activityType1 == 'modify' &&
                                        item[0].status == 'success'
                                      "
                                      label
                                      x-small
                                      color="success"
                                      >Modified</v-chip
                                    >
                                    <v-chip
                                      v-if="
                                        item[0].activityType1 == 'modify' &&
                                        item[0].status == 'failed'
                                      "
                                      label
                                      x-small
                                      color="error"
                                      >Modified failed</v-chip
                                    >
                                    <v-chip
                                      v-if="
                                        item[0].activityType1 == '' &&
                                        item[0].status == 'success'
                                      "
                                      label
                                      x-small
                                      color="error"
                                      >Canceled</v-chip
                                    >
                                    <v-chip
                                      v-if="
                                        item[0].activityType1 == '' &&
                                        item[0].status == 'failed'
                                      "
                                      label
                                      x-small
                                      color="error"
                                      >Cancel failed</v-chip
                                    >
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                    style="font-size: 12px"
                                    v-html="item[0].applicationNumber"
                                  ></v-list-item-subtitle>
                                </v-col>
                                <v-col style="text-align: right">
                                  <v-list-item-subtitle
                                    v-html="item.quantity1"
                                  ></v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    ><v-chip
                                      v-if="item.atCutOff == 'True'"
                                      x-small
                                      >Cut-Off</v-chip
                                    >{{ item.price1 }}</v-list-item-subtitle
                                  >
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </v-hover>
                      </template>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-overlay
              :absolute="absolute"
              opacity="1"
              :value="overlay"
              color="#dfdfdf"
              light
              style="
                margin-left: 75%;
                width: 25%;
                margin-top: 24%;
                height: 50%;
                text-align: left;
              "
            >
              <div style="width: 100%; height: 90%">
                <v-row>
                  <v-col>
                    <h3 style="color: black" v-if="orderinfo">
                      {{ orderinfo[0].symbol }}
                    </h3>
                    <h6 style="color: black" v-if="orderinfo">
                      {{ orderinfo[0].applicationNumber }}
                    </h6>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      x-small
                      text
                      color="grey"
                      @click="(overlay = false), (orderinfo = null)"
                      ><v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <div
                  style="
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 260px;
                    width: 340px;
                  "
                >
                  <div v-for="(item, i) in orderinfo" :key="i">
                    <v-chip
                      v-if="item.activityType1 == 'new'"
                      x-small
                      style="color: white; background-color: black"
                      >BID 1</v-chip
                    >

                    <div style="color: black">
                      <span v-if="item.activityType1 == 'new'"
                        ><v-icon v-if="item.status == 'success'" color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                        Open Order<br
                      /></span>
                      <span v-else-if="item.activityType1 == 'modify'"
                        ><v-icon v-if="item.status == 'success'" color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                        Modify Order<br
                      /></span>
                      <v-row v-if="item.activityType1 != ''" style="padding: 0">
                        <v-col cols="6">
                          <span v-if="item.bidReferenceNumber1"
                            >{{ item.bidReferenceNumber1 }}<br
                          /></span>
                          <span v-if="item.timestamp" style="font-size: 12px"
                            >{{ item.timestamp }}<br
                          /></span>
                        </v-col>
                        <v-col cols="5" style="text-align: right">
                          <span v-if="item.quantity1"
                            >{{ item.quantity1 }}<br
                          /></span>
                          <span v-if="item.amount1 && item.atCutOff1 == 'False'"
                            >₹ {{ item.price1 }}<br
                          /></span>
                          <span v-else
                            ><v-chip x-small label>CUT-OFF</v-chip></span
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <v-chip
                      v-if="item.activityType2 == 'new'"
                      x-small
                      style="color: white; background-color: black"
                      >BID 2</v-chip
                    >
                    <div style="color: black">
                      <span v-if="item.activityType2 == 'new'"
                        ><v-icon v-if="item.status == 'success'" color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                        Open Order<br
                      /></span>
                      <span v-else-if="item.activityType2 == 'modify'"
                        ><v-icon v-if="item.status == 'success'" color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                        Modify Order<br
                      /></span>
                      <v-row v-if="item.activityType2 != ''" style="padding: 0">
                        <v-col cols="6">
                          <span v-if="item.bidReferenceNumber2"
                            >{{ item.bidReferenceNumber2 }}<br
                          /></span>
                          <span v-if="item.timestamp" style="font-size: 12px"
                            >{{ item.timestamp }}<br
                          /></span>
                        </v-col>
                        <v-col cols="5" style="text-align: right">
                          <span v-if="item.quantity2"
                            >{{ item.quantity2 }}<br
                          /></span>
                          <span v-if="item.amount2 && item.atCutOff2 == 'False'"
                            >₹ {{ item.price2 }}<br
                          /></span>
                          <span v-else
                            ><v-chip x-small label>CUT-OFF</v-chip></span
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <v-chip
                      v-if="item.activityType3 == 'new'"
                      x-small
                      style="color: white; background-color: black"
                      >BID 3</v-chip
                    >
                    <div style="color: black">
                      <span v-if="item.activityType3 == 'new'"
                        ><v-icon v-if="item.status == 'success'" color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                        Open Order<br
                      /></span>
                      <span v-else-if="item.activityType3 == 'modify'"
                        ><v-icon v-if="item.status == 'success'" color="success"
                          >mdi-check-circle-outline</v-icon
                        >
                        Modify Order<br
                      /></span>
                      <v-row v-if="item.activityType3 != ''" style="padding: 0">
                        <v-col cols="6">
                          <span v-if="item.bidReferenceNumber3"
                            >{{ item.bidReferenceNumber3 }}<br
                          /></span>
                          <span v-if="item.timestamp" style="font-size: 12px"
                            >{{ item.timestamp }}<br
                          /></span>
                        </v-col>
                        <v-col cols="5" style="text-align: right">
                          <span v-if="item.quantity3"
                            >{{ item.quantity3 }}<br
                          /></span>
                          <span v-if="item.amount3 && item.atCutOff3 == 'False'"
                            >₹ {{ item.price3 }}<br
                          /></span>
                          <span v-else
                            ><v-chip x-small label>CUT-OFF</v-chip></span
                          >
                        </v-col>
                      </v-row>
                    </div>
                    <div style="color: black">
                      <span
                        v-if="
                          item.activityType1 == '' &&
                          item.activityType2 == '' &&
                          item.activityType3 == ''
                        "
                        ><v-icon v-if="item.status == 'success'" color="red"
                          >mdi-close-circle-outline</v-icon
                        >
                        Cancel Order<br
                      /></span>
                      <span
                        v-if="
                          item.activityType1 == '' &&
                          item.activityType2 == '' &&
                          item.activityType3 == ''
                        "
                        style="font-size: 12px"
                        >{{ item.timestamp }}<br
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </v-overlay>
          </div>
          <div
            class="pa-4 text-center"
            v-if="orderstatusdisplay && loginstatus"
          >
            <v-btn
              x-small
              text
              @click="
                (orderstatusdisplay = false),
                  (profiledisplay = true),
                  order_history()
              "
              style="float: right"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
            <v-card-text style="padding-top: 20px"
              ><h2>{{ orderstatus["symbol"] }}</h2>
            </v-card-text>
            <v-icon
              class="mx-auto"
              size="70"
              color="green"
              width="250"
              v-if="orderstatus['status'] != 'failed'"
              >mdi-checkbox-marked-circle</v-icon
            >
            <v-icon
              class="mx-auto"
              size="70"
              color="red"
              width="250"
              v-if="orderstatus['status'] == 'failed'"
              >mdi-close-circle</v-icon
            >

            <v-card-text
              ><h3>
                <span v-if="orderstatus['bids'].length != 0">{{
                  orderstatus["bids"][0]["activityType"]
                    .charAt(0)
                    .toUpperCase() +
                  orderstatus["bids"][0]["activityType"].slice(1)
                }}</span
                ><span v-else>Cancel</span> Order
                {{
                  orderstatus["status"].charAt(0).toUpperCase() +
                  orderstatus["status"].slice(1)
                }}
              </h3>
              <!-- <p style="font-size:14px;"> {{ orderstatus['bids'][0]['quantity'] }} x {{ orderstatus['bids'][0]['price'] }} </p>  -->
              <h2
                style="padding-top: 10px"
                v-if="orderstatus['bids'].length != 0"
              >
                ₹ {{ orderstatus["bids"][0]["amount"] }}
              </h2>
              <p style="padding-top: 10px; font-size: 12px">
                {{ orderstatus["timestamp"] }}
              </p></v-card-text
            >
            <!-- <v-card class="" style="text-align:left;font-size:14px;padding:5px;" color="#f2f2f2" v-if="orderstatus['status'] == 'failed'">
            <v-card-title style="padding:0;font-size:16px">Reason:</v-card-title>
            <v-card-text style="padding:0;font-size:12px">  {{ orderstatus['reason'] }}</v-card-text>
          </v-card> -->
            <v-divider v-if="orderstatus['bids'].length != 0"></v-divider>
            <v-row
              style="padding-top: 10px; padding-bottom: 10px"
              v-if="orderstatus['bids'].length != 0"
            >
              <v-col>
                <v-row>
                  <v-col style="padding-right: 0">
                    <v-icon style="margin: 7px; padding-left: 40px"
                      >mdi-briefcase-plus-outline</v-icon
                    >
                  </v-col>
                  <v-col style="text-align: left; padding-left: 0">
                    <!-- <div style="font-weight:normal;font-size:14px;">{{ orderstatus['bids'][0]['quantity'] }}
                  <small style="margin-top:-20px;font-size:12px;">Quantity</small>
                  </div> -->
                    <p
                      style="
                        margin-bottom: 0;
                        font-weight: normal;
                        font-size: 14px;
                      "
                    >
                      {{ orderstatus["bids"][0]["quantity"] }}
                    </p>
                    <p
                      class="text--disabled"
                      style="margin-bottom: 0; font-size: 12px"
                    >
                      Quantity
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="3" class="d-flex" style="padding-right: 0">
                    <v-icon style="margin: 7px">mdi-currency-inr</v-icon>
                  </v-col>
                  <v-col style="text-align: left; padding-left: 0">
                    <!-- <div style="font-weight:normal;font-size:14px;">{{ orderstatus['bids'][0]['quantity'] }}
                  <small style="margin-top:-20px;font-size:12px;">Quantity</small>
                  </div> -->
                    <p
                      v-if="!orderstatus['bids'][0]['atCutOff']"
                      style="
                        margin-bottom: 0;
                        font-weight: normal;
                        font-size: 14px;
                      "
                    >
                      {{ orderstatus["bids"][0]["price"] }}
                    </p>
                    <p
                      style="margin-bottom: 0"
                      v-if="orderstatus['bids'][0]['atCutOff']"
                    >
                      <v-chip x-small label>CUT-OFF</v-chip>
                    </p>
                    <p
                      class="text--disabled"
                      style="margin-bottom: 0; font-size: 12px"
                    >
                      Price
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider style=""></v-divider>
            <!-- <v-row>
            <v-col>
              <v-input
                :messages="['Bid ReferenceNumber']"
              >
                {{ orderstatus['bids'][0]['bidReferenceNumber'] }}
              </v-input>
            </v-col>
            <v-col>
              <v-input
                :messages="['Application Number']"
              >
                {{ orderstatus['applicationNumber'] }}
              </v-input>
            </v-col>
            <v-col>
              <v-input
                :messages="['UPI Id']"
              >
                {{ orderstatus['upi'] }}
              </v-input>
            </v-col>
          </v-row> -->

            <v-stepper elevation="0" style="padding-left: 50px">
              <v-stepper-step
                step="2"
                complete
                style="padding: 0; padding-top: 20px"
              >
                <span
                  v-if="orderstatus['bids'].length != 0"
                  style="font-weight: normal; font-size: 14px"
                  >{{
                    orderstatus["bids"][0]["activityType"]
                      .charAt(0)
                      .toUpperCase() +
                    orderstatus["bids"][0]["activityType"].slice(1)
                  }}
                  order</span
                >
                <span
                  v-if="orderstatus['bids'].length == 0"
                  style="font-weight: normal; font-size: 14px"
                  >Cancel</span
                >
                <small
                  class="text--disabled"
                  style="padding-top: 5px; font-size: 12px"
                  >Order Type</small
                >
              </v-stepper-step>

              <v-stepper-step
                step="2"
                complete
                style="padding: 0; padding-top: 20px"
              >
                <span style="font-weight: normal; font-size: 14px">{{
                  orderstatus["applicationNumber"]
                }}</span>
                <small
                  class="text--disabled"
                  style="padding-top: 5px; font-size: 12px"
                  >Application Number</small
                >
              </v-stepper-step>

              <v-stepper-step
                step="2"
                complete
                style="padding: 0; padding-top: 20px"
                v-if="orderstatus['bids'].length != 0"
              >
                <span style="font-weight: normal; font-size: 14px"
                  >{{ orderstatus["bids"][0]["bidReferenceNumber"] }}
                </span>
                <small
                  class="text--disabled"
                  style="padding-top: 5px; font-size: 12px"
                  >Bid Reference Number</small
                >
              </v-stepper-step>

              <v-stepper-step
                step="2"
                complete
                style="padding: 0; padding-top: 20px"
                color="success"
                v-if="orderstatus['status'] == 'success'"
              >
                <span style="font-weight: normal; font-size: 14px"
                  >Sucess
                </span>
                <small
                  class="text--disabled"
                  style="padding-top: 5px; font-size: 12px"
                  ><span v-if="orderstatus['bids'].length != 0">{{
                    orderstatus["bids"][0]["activityType"]
                      .charAt(0)
                      .toUpperCase() +
                    orderstatus["bids"][0]["activityType"].slice(1)
                  }}</span
                  ><span v-else>Cancel</span> Order completed</small
                >
              </v-stepper-step>

              <v-stepper-step
                :rules="[() => false]"
                step="3"
                v-if="orderstatus['status'] == 'failed'"
                style="padding: 0; padding-top: 20px"
              >
                <span style="font-weight: normal; font-size: 14px">{{
                  orderstatus["status"].charAt(0).toUpperCase() +
                  orderstatus["status"].slice(1)
                }}</span>
                <small
                  class="text--disabled"
                  style="padding-top: 5px; font-size: 12px"
                  >{{ orderstatus["reason"] }}</small
                >
              </v-stepper-step>
            </v-stepper>

            <!-- <v-card-text>atCutOff: {{ orderstatus['bids'][0]['atCutOff'] }}</v-card-text> -->
          </div>
        </v-col>
      </v-row>
    </v-expand-transition>

    <!--++++++@@ IPO Mobile View @@+++++-->
    <v-bottom-sheet
      v-model="onmobile.checkForMobile"
      class="d-block"
      hide-overlay
      width="100%"
      style="border-shadow: none"
    >
      <v-card
        v-if="onmobile.checkForMobile && $vuetify.breakpoint.mdAndDown"
        class=""
        fixed-header
        style="box-shadow: none; overflow: auto"
        height="400"
      >
        <div v-if="!loginstatus">
          <v-card
            elevation="0"
            v-if="clientcode_screen"
            height="400px"
            style="margin-top: 0; overflow: auto"
          >
            <v-card-title> Login</v-card-title>
            <v-card-text>
              <!-- <v-text-field
                            label="Client Code"
                            v-model="clientcode"
                            prepend-icon= mdi-account
                            placeholder="Enter client code"
                            @keyup="clientcode = clientcode.toUpperCase()"
                            validate
                            autocomplete="off"
                            :error-messages="userErrors" 
                            @input="$v.clientcode.$touch()"
                            @blur="$v.clientcode.$touch()"
                            :disabled="loader"
                            style="padding-bottom:15px;"
                       >    
              </v-text-field> -->
              <v-btn
                color="primary"
                block
                @click="clientcode_verify()"
                :disabled="loader"
                :loading="loader"
                >Sign in with Zebull
              </v-btn>
              <div align="center">
                <v-progress-linear
                  v-if="loader && clientcode_screen"
                  indeterminate
                  color="primary"
                  style="width: 99%"
                ></v-progress-linear>
              </div>
            </v-card-text>
          </v-card>
          <v-card
            elevation="0"
            v-if="otpverify_screen"
            style="padding-bottom: 40px"
          >
            <v-card-title
              ><v-btn rounded small text @click="back_from_otp"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              ></v-card-title
            >
            <div class="text-center">
              <v-avatar v-if="name" color="primary" size="60" style="">
                <span class="white--text text-h5">{{ name[0] }}</span>
              </v-avatar>
            </div>
            <v-card-text v-if="name" justify="center" align="center">
              <h4 class="primary--text">{{ clientcode }}</h4>
              <h3>{{ name }}</h3>
              <v-otp-input
                style="padding-top: 20px; padding-bottom: 10px"
                length="4"
                outlined
                primary
                v-model="otp"
                :disabled="loader"
                type="number"
                plain
              ></v-otp-input>

              <v-btn
                color="primary"
                block
                :disabled="loader"
                @click="otp_verify()"
                >Verify</v-btn
              >
              <div align="center">
                <v-progress-linear
                  v-if="loader && otpverify_screen"
                  indeterminate
                  color="primary"
                  style="width: 99%"
                ></v-progress-linear>
              </div>
              <span v-if="timerEnabled" style="font-size: 14px">
                <br />{{ timerCount }} Sec
              </span>
              <span v-if="resendText" style="font-size: 14px">
                <br />OTP didn't receive it
                <a @click="resend_otp()">(resend)</a>
              </span>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="loginstatus">
          <v-card-text style="">
            <div class="text-center justify-center align-center">
              <v-row>
                <v-col lg="2">
                  <v-list-item-avatar tile>
                    <v-img
                      style="border-radius: 10px"
                      :src="getAvatar(selectedsymbol)"
                    ></v-img>
                  </v-list-item-avatar>
                </v-col>
                <v-col style="text-align: left">
                  <h3 style="padding-top: 6px">{{ selectedsymbol }}</h3>
                  <h4>{{ selectedcompany }}</h4>
                </v-col>
              </v-row>
            </div>

            <!-- <div style="text-align:right; margin:10px -6px 0 0"><v-btn color="primary" rounded  @click="addRow(selectedqty)" v-if="basic.length < 3">Add<v-icon dark>mdi-plus</v-icon> </v-btn></div> -->
            <div
              v-for="(bitem, index) in basic"
              :key="bitem.id"
              style="padding-top: 20px"
            >
              <!-- <div style="float:right">
                                      <v-btn v-if="basic.length != 1" x-small  text  v-on:click="basic.splice(index, 1)" style="margin-top:1px;">
                                              <v-icon
                                                dark
                                                small
                                                color="grey"
                                                ele
                                              >
                                                mdi-close
                                              </v-icon>
                                      </v-btn>
                                    </div> -->
              <v-row
                align="center"
                style="padding-left: 10px; padding-right: 10px"
              >
                <v-divider></v-divider>
                <v-chip x-small style="color: white; background-color: black"
                  >BID {{ index + 1 }}</v-chip
                >
                <v-divider></v-divider>
                <v-btn
                  v-if="basic.length != 1"
                  x-small
                  text
                  v-on:click="basic.splice(index, 1)"
                  style="margin-top: 1px"
                >
                  <v-icon dark small color="grey" ele> mdi-close </v-icon>
                </v-btn>
              </v-row>
              {{ index }}
              <div style="padding-top: 10px">
                <v-text-field
                  type="number"
                  v-model="bitem.qty"
                  :step="selectedqty"
                  :min="selectedqty"
                  @input="$v.bitem.qty.$touch()"
                  @blur="$v.bitem.qty.$touch()"
                >
                  <template v-slot:label>
                    <strong>Qty</strong> (Lot : {{ selectedqty }} *
                    {{ parseInt(bitem.qty / selectedqty) }})
                  </template>
                </v-text-field>

                <v-text-field
                  type="number"
                  v-model="bitem.prc"
                  prefix="₹"
                  :disabled="bitem.cutoff"
                  :min="selectedminprc"
                  :max="selectedmaxprc"
                >
                  <template v-slot:label>
                    <strong>Price</strong> (₹{{ selectedminprc }} - ₹{{
                      selectedmaxprc
                    }})
                  </template>
                </v-text-field>
              </div>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="bitem.cutoff"
                    x-small
                    label="Cut-off"
                    style="
                      padding: 0;
                      margin-top: -10px;
                      transform: scale(0.9);
                      height: -10px;
                    "
                    @click="bitem.prc = selectedmaxprc"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    text
                    x-small
                    :@click="addRow(selectedqty, selectedminprc)"
                    :disabled="basic.length > index + 1 || basic.length > 2"
                    style="
                      padding: 0;
                      margin-top: -6px;
                      float: right;
                      padding: 10px;
                    "
                    ><v-icon dark small>mdi-plus</v-icon> BID
                    {{ index + 2 }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <v-row no-gutters style="padding-top: 20px">
              <v-col cols="8">
                <v-text-field
                  v-model="vpa"
                  hide-details
                  label="UPI Id"
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <!-- <v-select
                  hide-details
                  v-model="upibank"
                  :items="upiitems"
                  label="@upi bank"
                  dense
                ></v-select> -->
              </v-col>
            </v-row>
            <v-checkbox v-model="ordercheckbox">
              <template v-slot:label>
                <p
                  style="
                    font-size: 12px;
                    margin-bottom: -50px;
                    text-align: justify;
                  "
                >
                  I hereby undertake that, I have read the Red Herring
                  Prospectus and iam an eligible UPI bidder as per the
                  applicable provisions of the SEBI (Issue of capital and
                  Disclosure Requirement) Regulation, 2009.
                </p>
              </template>
            </v-checkbox>
            <v-btn
              style="margin-top: 50px"
              block
              :disabled="!ordercheckbox"
              color="primary"
              @click="order()"
            >
              invest<v-icon small>mdi-currency-inr</v-icon>
              <span v-if="basic.length == 1"
                >{{
                  isNaN(
                    Math.max.apply(null, [parseInt(basic[0]["qty"]) * parseInt(basic[0]["prc"]),
                    ])
                  )
                    ? 0
                    : Math.max.apply(null, [
                        parseInt(basic[0]["qty"]) * (parseInt(basic[0]["prc"])-parseInt(45)),
                      ])
                }}
              </span>
              <span v-if="basic.length == 2"
                >{{
                  isNaN(
                    Math.max.apply(null, [
                      parseInt(basic[0]["qty"]) * parseInt(basic[0]["prc"]),
                      parseInt(basic[1]["qty"]) * parseInt(basic[1]["prc"]),
                      0,
                    ])
                  )
                    ? 0
                    : Math.max.apply(null, [
                        parseInt(basic[0]["qty"]) * (parseInt(basic[0]["prc"])-parseInt(45)),
                        parseInt(basic[1]["qty"]) * (parseInt(basic[1]["prc"])-parseInt(45)),
                        0,
                      ])
                }}
              </span>
              <span v-if="basic.length == 3"
                >{{
                  isNaN(
                    Math.max.apply(null, [
                      parseInt(basic[0]["qty"]) * parseInt(basic[0]["prc"]),
                      parseInt(basic[1]["qty"]) * parseInt(basic[1]["prc"]),
                      parseInt(basic[2]["qty"]) * parseInt(basic[2]["prc"]),
                      0,
                    ])
                  )
                    ? 0
                    : Math.max.apply(null, [
                        parseInt(basic[0]["qty"]) * (parseInt(basic[0]["prc"])-parseInt(45)),
                        parseInt(basic[1]["qty"]) * (parseInt(basic[1]["prc"])-parseInt(45)),
                        parseInt(basic[2]["qty"]) * (parseInt(basic[2]["prc"])-parseInt(45)),
                        0,
                      ])
                }}
              </span>
            </v-btn>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DarkModeSwitch from "vue-dark-mode-switch";
import "vue-dark-mode-switch/dist/vue-dark-mode-switch.css";
export default {
  name: "Home",
  mixins: [validationMixin],
  validations: {
    clientcode: { required },
  },
  data: () => ({
    drawer: false,
    onmobile: {
      checkForMobile: false,
    },
    checkforopen: "",
    ipoendtiming:'',
    currentdate: 0,
    stepsize: 0,
    absolute: false,
    rightcolno: 12,
    leftcolno: 0,
    desserts: [],
    desc_data: [],
    skeleton_loader: true,
    clientcode: null,
    name: null,
    pan: null,
    mail: null,
    mobile: null,
    clientcode_validate: false,
    loader: false,
    clientcode_screen: true,
    otpverify_screen: false,
    otp: null,
    timerEnabled: false,
    resendText: false,
    timerCount: 121,
    loginstatus: false,
    vpa: null,
    overlay: false,
    upiitems: [
      "@okicici",
      "@oksbi",
      "@okhdfcbank",
      "@okaxis",
      "@okkotack",
      "@okyesbank",
      "@okiob",
      "@oksbi",
      "@okhdfcbank",
    ],
    upibank: null,
    basic: [{ qty: "", prc: 0, cutoff: false }],
    cancel_basic: null,
    selectedqty: null,
    selectedsymbol: null,
    selectedminprc: null,
    selectedmaxprc: null,
    selectedcompany: null,
    selectedtickprc: null,
    ordercheckbox: false,
    isDarkModeEnabled: false,
    ordererrormessage: null,
    qty1errormsg: null,
    prc1errormsg: null,
    qty2errormsg: null,
    prc2errormsg: null,
    qty3errormsg: null,
    prc3errormsg: null,
    priceexceederror: null,
    ordertimedisp: false,
    currenttime: null,
    orderdisplay: false,
    profiledisplay: false,
    tab: null,
    orderhistory: [],
    orderstatusdisplay: false,
    orderstatus: null,
    modifyscript: [],
    modifydisplay: false,
    app_no: null,
    orderinfo: null,
  }),

  created(){
       const axiosThis = this;
      var config = {
        method: "post",
        url: "https://ipobe.zebull.in/ipo-openissues",
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log("First i was updated : ",JSON.stringify(response.data));
          axiosThis.desserts = response.data.reverse();
        })
        .catch(function (error) {
          console.log(error);
        });
  },
  mounted() {
    var axiosThis = this;
    var hash = localStorage.getItem("ubez");
    const now = new Date();
    var url = new URL(window.location.href);
    var authCode = url.searchParams.get("authCode");
    console.log("authCode : ",authCode)
    if (authCode != null) {
      var ssoconfig = {
        method: "post",
        url:
          "https://ipobe.zebull.in/ssoclient_check?code=" +
          authCode +
          "&ubez=" +
          btoa(hash),
        headers: {},
      };
      axios(ssoconfig)
        .then(function (response) {
          console.log(JSON.stringify(response.data));

          if (response.data != "Invalid Client Code") {
            console.log("All in Comming! " + response.data);
            localStorage.setItem("cid", btoa(response.data[0]));
            axiosThis.loader = false;
            axiosThis.loginstatus = true;
            axiosThis.drawer = true;
            axiosThis.profiledisplay = true;
            axiosThis.clientcode_validate = false;
            axiosThis.name = response.data[2];
            axiosThis.pan = response.data[1];
            axiosThis.mail = response.data[4];
            axiosThis.mobile = response.data[5];
            localStorage.setItem("ofni", btoa(response.data));
             axiosThis.ordertime();
            axiosThis.order_history();

            localStorage.setItem(
              "vald",
              btoa(now.setSeconds(now.getSeconds() + 900))
            );
           
            axiosThis.drawer_function();
          } else {
            console.log("Client Code Error");
            axiosThis.loader = false;
            axiosThis.clientcode_validate = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (
      localStorage.getItem("vald") &&
      localStorage.getItem("ubez") &&
      localStorage.getItem("cid")
    ) {
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > localStorage.getItem("vald")) {
        localStorage.removeItem("vald");
        localStorage.removeItem("ubez");
        return null;
      } else {
        const axiosThis = this;
        var config = {
          method: "post",
          url:
            "https://ipobe.zebull.in/session?id=" +
            localStorage.getItem("cid") +
            "&sess=" +
            localStorage.getItem("ubez"),
          headers: {},
        };
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            if (JSON.stringify(response.data) == '"Active"') {
              console.log("Login Status Active");
              console.log("Info:" + atob(localStorage.getItem("ofni")));
              var data = atob(localStorage.getItem("ofni")).split(",");
              console.log("Client Code :" + data[0]);
              axiosThis.clientcode = data[0];
              axiosThis.pan = data[1];
              axiosThis.name = data[2];
              axiosThis.mail = data[4];
              axiosThis.mobile = data[5];
              axiosThis.loginstatus = true;
              axiosThis.profiledisplay = true;
              axiosThis.drawer = true;
              axiosThis.drawer_function();
            } else {
              console.log("Log Off");
              axiosThis.loginstatus = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    var configopenissues = {
      method: "post",
      url: "https://ipobe.zebull.in/ipo-openissues",
      headers: {},
    };
    axios(configopenissues)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        axiosThis.items = response.data;
        console.log(axiosThis.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  components: {
    DarkModeSwitch,
  },
  computed: {
    // userErrors() {
    //   const errors = [];
    //   console.log("Ccode v :", this.clientcode_validate, this.clientcode);
    //   var cval = this.clientcode_validate;
    //   if (!this.$v.clientcode.$dirty) return errors;
    //   if (cval == 1) {
    //     !this.$v.clientcode.validate &&
    //       errors.push("Client id is not available");
    //   }
    //   !this.$v.clientcode.required && errors.push("Please Enter User ID.");
    //   //console.log("userErrors : ", errors);
    //   return errors;
    // },
  },
  // watch: {
  //   timerEnabled(value) {
  //     if (value) {
  //       setTimeout(() => {
  //         this.timerCount--;
  //       }, 1000);
  //     }
  //   },
  //   timerCount: {
  //     handler(value) {
  //       if (value > 0 && this.timerEnabled) {
  //         setTimeout(() => {
  //           this.timerCount--;
  //         }, 1000);
  //       }
  //       if (value == 0) {
  //         this.timerEnabled = false;
  //         this.resendText = true;
  //       }
  //     },
  //     immediate: true, // This ensures the watcher is triggered upon creation
  //   },
  // },
  methods: {
    ordertime() {
      const d = new Date();
      var hour = d.getHours();
      this.currenttime = hour;
      console.log("\n\nipo time : ",(this.desserts[0]['dailyEndTime']).slice(0,2),"\n\n")
      this.ipoendtiming=this.desserts[0]['dailyEndTime'].slice(0,2)
      if (hour < 10 || hour >= this.desserts[0]['dailyEndTime'].slice(0,2)) {
        this.ordertimedisp = true;
      } else {
        this.ordertimedisp = false;
      }
    },
    order() {
      var symbol = this.selectedsymbol;
      var minqty = this.selectedqty;
      var minprc = this.selectedminprc;
      var maxprc = this.selectedmaxprc;
      var basic = JSON.stringify(this.basic);
      console.log("Basic :", basic);
      var data = atob(localStorage.getItem("ofni")).split(",");
      console.log("Order Data : " + data);
      var clientcode = data[0];
      var pan = data[1];
      var name = data[2];
      var mail = data[4];
      var mobile = data[5];
      var vpa = this.vpa;
      console.log("Bank : ", this.vpa);
      console.log("order()  placing section : ",
        symbol,
        minqty,
        minprc,
        maxprc,
        basic,
        clientcode,
        pan,
        name,
        mail,
        mobile,
        vpa
      );
      var orequest = btoa([
        symbol,
        minqty,
        minprc,
        maxprc,
        clientcode,
        pan,
        name,
        mail,
        mobile,
        vpa,
      ]);
      const axiosThis = this;
      var config = {
        method: "post",
        url:
          "https://ipobe.zebull.in/ipo-order?orequest=" +
          orequest +
          "&basic=" +
          basic,
        headers: {},
      };
      axios(config,{mode:'cors'})
        .then(function (response) {
          console.log(JSON.stringify(response.data["bids"]));
          axiosThis.loader = false;
          axiosThis.orderstatusdisplay = true;
          axiosThis.orderdisplay = false;
          axiosThis.profiledisplay = false;
          axiosThis.orderstatus = response.data;
          console.log(
            typeof response.data["bids"],
            response.data["bids"],
            response.data["bids"].length
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    modifyorderplace() {
      this.loader = true;
      var symbol = this.selectedsymbol;
      var minqty = this.selectedqty;
      var minprc = this.selectedminprc;
      var maxprc = this.selectedmaxprc;

      var basic = JSON.stringify(this.basic);

      console.log("Basic :", basic);
      var data = atob(localStorage.getItem("ofni")).split(",");
      console.log("Order Data : " + data);
      var clientcode = data[0];
      var pan = data[1];
      var name = data[2];
      var mail = data[4];
      var mobile = data[5];
      var vpa = this.vpa;
      var app_no = this.app_no;
      console.log("Bank : ", this.vpa);
      console.log(
        symbol,
        minqty,
        minprc,
        maxprc,
        basic,
        clientcode,
        pan,
        name,
        mail,
        mobile,
        vpa,
        app_no
      );
      var orequest = btoa([
        symbol,
        minqty,
        minprc,
        maxprc,
        clientcode,
        pan,
        name,
        mail,
        mobile,
        vpa,
        app_no,
      ]);
      const axiosThis = this;
      var config = {
        method: "post",
        url:
          "https://ipobe.zebull.in/ipo-order-modify?orequest=" +
          orequest +
          "&basic=" +
          basic,
        headers: {},
      };
      axios(config)
        .then(function (response){
          console.log(JSON.stringify(response.data));
          axiosThis.orderstatusdisplay = true;
          axiosThis.orderdisplay = false;
          axiosThis.profiledisplay = false;
          axiosThis.orderstatus = response.data;
          axiosThis.loader = false;
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.loader = false;
        });
    },
    cancelorderplace() {
      this.loader = true;
      var symbol = this.selectedsymbol;
      var minqty = this.selectedqty;
      var minprc = this.selectedminprc;
      var maxprc = this.selectedmaxprc;

      var basic = JSON.stringify(this.basic);

      console.log("Basic :", basic);
      var data = atob(localStorage.getItem("ofni")).split(",");
      console.log("Order Data : " + data);
      var clientcode = data[0];
      var pan = data[1];
      var name = data[2];
      var mail = data[4];
      var mobile = data[5];
      var vpa = this.vpa;
      var app_no = this.app_no;
      console.log("Bank : ", this.vpa);
      console.log(
        symbol,
        minqty,
        minprc,
        maxprc,
        basic,
        clientcode,
        pan,
        name,
        mail,
        mobile,
        vpa,
        app_no
      );
      var orequest = btoa([
        symbol,
        minqty,
        minprc,
        maxprc,
        clientcode,
        pan,
        name,
        mail,
        mobile,
        vpa,
        app_no,
      ]);
      const axiosThis = this;
      var config = {
        method: "post",
        url:
          "https://ipobe.zebull.in/ipo-order-cancel?orequest=" +
          orequest +
          "&basic=" +
          basic,
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          axiosThis.orderstatusdisplay = true;
          axiosThis.orderdisplay = false;
          axiosThis.profiledisplay = false;
          axiosThis.orderstatus = response.data;
          axiosThis.loader = false;
          console.log(
            typeof response.data["bids"],
            response.data["bids"],
            response.data["bids"].length
          );
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.loader = false;
        });
    },
    order_history() {
      console.log("Order History!  :" + atob(localStorage.getItem("ofni")));
      var data = atob(localStorage.getItem("ofni")).split(",");
      console.log("Client Code :" + data[0]);
      var pan = data[1];
      var axiosThis = this;
      console.log("PAN History:" + pan);
      var config = {
        method: "post",
        url: "https://ipobe.zebull.in/ipo-order-history?id=" + btoa(pan),
        headers: {},
      };
      axios(config)
        .then(function (response) {
          axiosThis.orderhistory = response.data.reverse();
          console.log("Order History :" + JSON.stringify(response.data));
          var scrip_deleted = [];
          for (let i = 0; i < response.data.length; i++) {
            if (
              !axiosThis.modifyscript.includes(response.data[i].symbol) &&
              response.data[i].status == "success"
            )
              if (
                response.data[i].activityType1 == "new" ||
                response.data[i].activityType1 == "" ||
                response.data[i].activityType2 == "new" ||
                response.data[i].activityType3 == "new"
              ) {
                if (response.data[i].activityType1 == "") {
                  scrip_deleted.push(response.data[i].symbol);
                }
                if (!scrip_deleted.includes(response.data[i].symbol)) {
                  axiosThis.modifyscript.push(response.data[i].symbol);
                }
              }
          }
          console.log("Delete History :" + scrip_deleted);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    qty1error(index) {
      if (index == 0) {
        if (parseInt(this.basic[0].qty) % parseInt(this.selectedqty) != 0) {
          this.qty1errormsg = "Invalid Qty";
        } else {
          this.qty1errormsg = null;
        }
      }
      if (index == 1) {
        if (parseInt(this.basic[1].qty) % parseInt(this.selectedqty) != 0) {
          this.qty2errormsg = "Invalid Qty";
        } else {
          this.qty2errormsg = null;
        }
      }
      if (index == 2) {
        if (parseInt(this.basic[2].qty) % parseInt(this.selectedqty) != 0) {
          this.qty3errormsg = "Invalid Qty";
        } else {
          this.qty3errormsg = null;
        }
      }
      if (this.basic.length == 1) {
        if (
          parseInt(this.basic[0].qty) * parseInt(this.basic[0].prc) >
          200000
        ) {
          this.priceexceederror =
            "The Retail category can bid maximum upto ₹2 Lakhs";
        } else {
          this.priceexceederror = null;
        }
      }
      if (this.basic.length == 2) {
        if (
          parseInt(this.basic[0].qty) * parseInt(this.basic[0].prc) > 200000 ||
          parseInt(this.basic[1].qty) * parseInt(this.basic[1].prc) > 200000
        ) {
          this.priceexceederror =
            "The Retail category can bid maximum upto ₹2 Lakhs";
        } else {
          this.priceexceederror = null;
        }
      }
      if (this.basic.length == 3) {
        if (
          parseInt(this.basic[0].qty) * parseInt(this.basic[0].prc) > 200000 ||
          parseInt(this.basic[1].qty) * parseInt(this.basic[1].prc) > 200000 ||
          parseInt(this.basic[2].qty) * parseInt(this.basic[2].prc) > 200000
        ) {
          this.priceexceederror =
            "The Retail category can bid maximum upto ₹2 Lakhs";
        } else {
          this.priceexceederror = null;
        }
      }
    },
    prc1error(index) {
      if (index == 0) {
        if (parseInt(this.basic[0].prc) > parseInt(this.selectedmaxprc)) {
          this.prc1errormsg = "Price Limit Exceeds";
        } else if (
          parseInt(this.basic[0].prc) < parseInt(this.selectedminprc)
        ) {
          this.prc1errormsg = "Price Limit is Low";
        } else {
          this.prc1errormsg = null;
        }
      }
      if (index == 1) {
        if (parseInt(this.basic[1].prc) > parseInt(this.selectedmaxprc)) {
          this.prc2errormsg = "Price Limit Exceeds";
        } else if (
          parseInt(this.basic[1].prc) < parseInt(this.selectedminprc)
        ) {
          this.prc2errormsg = "Price Limit is Low";
        } else {
          this.prc2errormsg = null;
        }
      }
      if (index == 2) {
        if (parseInt(this.basic[2].prc) > parseInt(this.selectedmaxprc)) {
          this.prc3errormsg = "Price Limit Exceeds";
        } else if (
          parseInt(this.basic[2].prc) < parseInt(this.selectedminprc)
        ) {
          this.prc3errormsg = "Price Limit is Low";
        } else {
          this.prc3errormsg = null;
        }
      }
      if (this.basic.length == 1) {
        if (
          parseInt(this.basic[0].qty) * parseInt(this.basic[0].prc) >
          200000
        ) {
          this.priceexceederror =
            "The Retail category can bid maximum upto ₹2 Lakhs";
        } else {
          this.priceexceederror = null;
        }
      }
      if (this.basic.length == 2) {
        if (
          parseInt(this.basic[0].qty) * parseInt(this.basic[0].prc) > 200000 ||
          parseInt(this.basic[1].qty) * parseInt(this.basic[1].prc) > 200000
        ) {
          this.priceexceederror =
            "The Retail category can bid maximum upto ₹2 Lakhs";
        } else {
          this.priceexceederror = null;
        }
      }
      if (this.basic.length == 3) {
        if (
          parseInt(this.basic[0].qty) * parseInt(this.basic[0].prc) > 200000 ||
          parseInt(this.basic[1].qty) * parseInt(this.basic[1].prc) > 200000 ||
          parseInt(this.basic[2].qty) * parseInt(this.basic[2].prc) > 200000
        ) {
          this.priceexceederror =
            "The Retail category can bid maximum upto ₹2 Lakhs";
        } else {
          this.priceexceederror = null;
        }
      }
    },
    onSwitched: function (isSwitched) {
      console.log("dark mode is enabled :", isSwitched);
      if (isSwitched) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    drawer_function() {
      if (this.drawer == true && this.$vuetify.breakpoint.lg) {
        this.rightcolno = 9;
        this.leftcolno = 3;
      } else {
        this.rightcolno = 12;
        this.leftcolno = 0;
      }
    },
    // Written to check current(today) date and update ipo latest updated info 
    // checkcurrentdate() {
    //   var current = new Date();
    //   var day = current.getDate();
    //   var month = current.getMonth() + 1;
    //   var year = current.getFullYear();
    //   console.log("date is ", day, month, year);
    //   if (day > 0 && day <= 9) {
    //     day = String("0" + day);
    //   }
    //   if (month > 0 && month <= 9) {
    //     month = String("0" + month);
    //   }
    //   this.currentdate = year + "-" + month + "-" + day;
    //   console.log(
    //     "date before number ",
    //     this.currentdate,
    //     typeof this.currentdate
    //   );
    // },
    // Written to measure ipo current date status
    checkipostatus(
      open_date,
      close_date,
      allotment_date,
      initial_refund,
      share_dm,
      listing_date
    ) {
      //  console.log("data from checkipostatus() date ",open_date,close_date,allotment_date,initial_refund,share_dm,listing_date)
      var d1 = open_date.split("/");
      var d2 = close_date.split("/");
      var d3 = allotment_date.split("/");
      var d4 = initial_refund.split("/");
      var d5 = share_dm.split("/");
      var d6 = listing_date.split("/");
      //  this.currentdate
      var current = this.currentdate.split("-");

      var dd1 = new Date(d1[2], parseInt(d1[0]) - 1, d1[1]);
      var dd2 = new Date(d2[2], parseInt(d2[0]) - 1, d2[1]);
      var dd3 = new Date(d3[2], parseInt(d3[0]) - 1, d3[1]);
      var dd4 = new Date(d4[2], parseInt(d4[0]) - 1, d4[1]);
      var dd5 = new Date(d5[2], parseInt(d5[0]) - 1, d5[1]);
      var dd6 = new Date(d6[2], parseInt(d6[0]) - 1, d6[1]);
      var checker = new Date(current[0], parseInt(current[1]) - 1, current[2]);

      // console.log("checker ",checker);
      // console.log("dd1   ",dd1,dd2,dd3,dd4,dd5,dd6);

      if (checker >= dd1 && checker <= dd6) {
        if (checker == dd1) {
          this.stepsize = 1;
        } else if (checker <= dd2) {
          this.stepsize = 2;
        } else if (checker <= dd3) {
          this.stepsize = 3;
        } else if (checker <= dd4) {
          this.stepsize = 4;
        } else if (checker <= dd5) {
          this.stepsize = 5;
        } else if (checker <= dd6) {
          this.stepsize = 6;
        }
      } else if (checker < dd1) {
        this.stepsize = 1;
      } else if (checker > dd6) {
        this.stepsize = 7;
      }
    },
    onmobile_checker() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.onmobile.checkForMobile = true;
      }
    },
    getAvatar(symbol) {
      var src = null;
      try {
        src = require("@/assets/scriptlogo/" + symbol + ".jpg");
      } catch {
        src = require("@/assets/scriptlogo/SCET_0005.png");
      }
      return src;
    },
  
    info_descrip(symbol) {
      var axiosThis = this;
      var dat = {
        method: "post",
        url:
          "https://ipobe.zebull.in/ipo-admin-get-selected-desc?symbol=" +
          symbol,
        headers: {},
      };
      axios(dat)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          axiosThis.desc_data = response.data;
          axiosThis.skeleton_loader = false;
          console.log(axiosThis.desc_data);
        })
        .catch(function (error) {
          console.log(error);
        });
      console.log("Info Description :" + symbol);
    },
    // SSO Redirect section
    clientcode_verify() {
      //this.logincardloader = true;
      var hash = new Date().toLocaleString();
      localStorage.setItem("ubez", btoa(hash));
      //  var url='https://zebull.in/?mode=SSO&vendor=MyZebu&redirectUrl=https%3A%2F%2Fzipo.zebull.in%2F'
      var url =
        "https://zebull.in/?mode=SSO&vendor=MyZebu&redirectUrl=https%3A%2F%2Fzipo.zebull.in%2F";
      window.location.replace(url);
      this.otperror = false;
      this.loader = true;
    },
    back_from_otp() {
      this.$v.$reset();
      this.clientcode = null;
      this.otp = null;
      this.clientcode_screen = true;
      this.otpverify_screen = false;
      this.timerEnabled = false;
      this.resendText = false;
      this.timerCount = 121;
    },
  
    selected_ipo(symbol, qty, minprc, maxprc, companyname, tickprc) {
      this.selectedsymbol = symbol;
      this.selectedqty = qty;
      this.selectedminprc = minprc;
      this.selectedmaxprc = maxprc;
      this.selectedcompany = companyname;
      this.selectedtickprc = parseFloat(tickprc);
    },
    addRow(lot, price) {
      this.id += 1;
      this.basic.push({
        qty: parseInt(lot),
        prc: parseInt(price),
        cutoff: false,
      });
    },
    logout() {
      var axiosThis = this;
      // var config = {
      //   method: "post",
      //   url:
      //     "https://ipobe.zebull.in/logout?id=" + localStorage.getItem("cid"),
      //   headers: {},
      // };
      // axios(config)
      //   .then(function (response) {
      //     console.log(JSON.stringify(response.data));
          axiosThis.$v.$reset();
          axiosThis.loader = false;
          axiosThis.clientcode = null;
          axiosThis.otp = null;
          axiosThis.loginstatus = false;
          axiosThis.otpverify_screen = false;
          axiosThis.timerEnabled = false;
          axiosThis.timerCount = 121;
          axiosThis.resendText = false;
          axiosThis.clientcode_screen = true;
          axiosThis.orderhistory = null;
          localStorage.removeItem("vald");
          localStorage.removeItem("ubez");
          localStorage.removeItem("ofni");
           window.location.replace('/');
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });
    },

    modifyorder(symbol) {
      this.modifydisplay = true;
      console.log("Modify Symbol :" + symbol, this.orderhistory);
      var order_hist = this.orderhistory;
      var order_data = [];
      for (let i = 0; i < order_hist.length; i++) {
        if (
          order_hist[i].symbol == symbol &&
          order_hist[i].status == "success"
        ) {
          console.log("Hello :" + JSON.stringify(order_hist[i]));

          order_data.push(order_hist[i]);
        }
      }
      console.log("Lala :", order_data);
      var i = 0;
      if (order_data[i]["quantity1"] != 0) {
        this.basic[0].qty = parseInt(order_data[i].quantity1);
        this.basic[0].prc = parseInt(order_data[i].price1);
        this.basic[0].cutoff = order_data[i].atCutOff1 === "True";
        this.basic[0]["bidReferenceNumber"] = parseInt(
          order_data[i].bidReferenceNumber1
        );
        this.basic[0]["amount"] = parseInt(order_data[i].amount1);
        this.basic[0]["activityType"] = "modify";
      }
      if (order_data[i]["quantity2"] != 0) {
        if (this.basic.length > 1) {
          this.basic[1].qty = parseInt(order_data[i].quantity2);
          this.basic[1].prc = parseInt(order_data[i].price2);
          this.basic[1].cutoff = order_data[i].atCutOff2 === "True";
          this.basic[1]["amount"] = parseInt(order_data[i].amount2);
          this.basic[1]["activityType"] = "modify";
          this.basic[1]["bidReferenceNumber"] = parseInt(
            order_data[i].bidReferenceNumber2
          );
        } else {
          this.basic.push({
            qty: parseInt(order_data[i].quantity2),
            prc: parseInt(order_data[i].price2),
            cutoff: order_data[i].atCutOff2 === "True",
            amount: parseInt(order_data[i].amount2),
            activityType: "modify",
            bidReferenceNumber: parseInt(order_data[i].bidReferenceNumber2),
          });
        }
      }
      if (order_data[i]["quantity3"] != 0) {
        if (this.basic.length > 2) {
          this.basic[2].qty = parseInt(order_data[i].quantity3);
          this.basic[2].prc = parseInt(order_data[i].price3);
          this.basic[2].cutoff = order_data[i].atCutOff3 === "True";
          this.basic[2]["amount"] = parseInt(order_data[i].amount3);
          this.basic[2]["activityType"] = "modify";
          this.basic[2]["bidReferenceNumber"] = parseInt(
            order_data[i].bidReferenceNumber3
          );
        } else {
          this.basic.push({
            qty: parseInt(order_data[i].quantity3),
            prc: parseInt(order_data[i].price3),
            cutoff: order_data[i].atCutOff3 === "True",
            amount: parseInt(order_data[i].amount3),
            activityType: "modify",
            bidReferenceNumber: parseInt(order_data[i].bidReferenceNumber3),
          });
        }
      }
      this.vpa = order_data[i]["upi"];
      this.app_no = order_data[i]["applicationNumber"];
    },
  },
};
</script>
<style>
.rightCardFloat {
  position: sticky !important;
  top: 80px !important;
  z-index: 1 !important;
}

.fontChange {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
}
.font-style {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
}
body {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
}
button,
input,
optgroup,
select,
textarea,
span,
small,
p,
h1,
h2,
h3,
h4,
h5,
div {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
}
.v-stepper__header {
  height: 100% !important;
}

#box {
  overflow: scroll;
  width: 382px;
  height: 110vh;
  position: sticky;
}
::-webkit-scrollbar {
  width: 08px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #e3e4e4;
}
</style>